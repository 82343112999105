
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .splash-image {
    animation: fadeIn 0.3s ease-in forwards;
  }


  .splash-image.pulse {
    animation: pulseOut 0.2s ease-in forwards;
  }

  @media (min-width: 769px) {
    .splash-image {
      width: 10rem;
    }
  }
  
  /* For mobile screens (768px and below) */
  @media (max-width: 768px) {
    .splash-image {
      width: 30vw;
    }
  }




  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes pulseOut {
    0% {
      transform: scale(1)
    }
    100% {
      transform: scale(100);
      opacity:0;
    }
  }